import { getToken, setToken, handleLogout } from "@/helpers/auth";
import router from '@/router/index'

class Request {
    constructor(parms) {
        this.withBaseURL = parms.withBaseURL
        this.baseURL = parms.baseURL
    }
    request(parames) {
        let url = parames.url || ''
        let method = parames.method || 'GET'
        let data = parames.data || ''
        this.withBaseURL = (url.indexOf('http') === -1)
        let requestUrl = this.withBaseURL ? this.baseURL + url : url;
        let headers = parames.headers ?? {
            'Content-Type': 'application/json',
            'x-requested-with': 'XMLHttpRequest',
            'Accept': 'application/json'
        }
        const token = getToken();
        if (getToken()) {
            headers['Authorization'] = token;
        }
        let options = {
            method,
            headers: headers,
            body: parames.body ?? JSON.stringify(data)
        }
        if (method.toUpperCase() === 'GET') {
            delete options.body;
            let qs = '?';
            for (const key in parames.data) {
                qs += key + '=' + parames.data[key] + '&'
            }
            qs = qs.substring(0, qs.length - 1)
            qs.length > 1 ? requestUrl += qs : ''
        }
        return fetch(requestUrl, options)
            .then(function (response) {
                return handleResponse(response);
            })
            .then(function (response) {
                return response
            })
    }
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                handleLogout();
                location.reload();
            }
            if (response.status === 403) {
                router.replace("/403");
            }
            if (response.status === 404) {
                router.replace("/404");
            }
            return Promise.reject(error);
        }
        const token = response.headers.get('authorization')
        if (token) {
            // if there is 'authorization' in the header, renew token
            setToken(token);
        }
        return text && JSON.parse(text);
    });
}
const service = new Request({
    baseURL: process.env.VUE_APP_API_URL,
    withBaseURL: true
})


export default service