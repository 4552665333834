import request from '@/helpers/request';

export function login(data) {
  return request.request({
    url: '/login',
    method: 'post',
    data: data,
  });
}

export function logout() {
  return request.request({
    url: '/logout',
    method: 'get',
  });
}
