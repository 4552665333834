import { login, logout } from '@/api/auth';
import { update, getInfo } from "@/api/user";
import { handleLogin, handleLogout, renewUser } from "@/helpers/auth";
import router from '@/router/index'

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.user
    },
}

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password, redirect }) {
        commit('LOGIN_REQUEST');

        login({ email, password })
            .then(response => {
                const user = response.user || null
                const token = response.token || ''
                // login successful if there's a jwt token in the response
                if (!token) {
                    throw "No user response"
                } else {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    commit('CHANGE_USER', user);
                    handleLogin(user, token);
                    router.push(redirect || '/');
                }
            }).catch(error => {
                commit('CLEAN_USER', error);
                dispatch('notification/error', error, { root: true });
            });
    },
    // Logout the user
    logout({ commit }) {
        // remove user from local storage to log user out
        handleLogout();
        commit('CLEAN_USER');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },

    updateUser({ dispatch, commit }, data) {
        update(data).then(
            response => {
            const user = response.data || null
                commit('CHANGE_USER', user);
                renewUser(user);
                location.reload();
            }
        );
    },

    reloadUser({ commit }) {
        getInfo().then(
            response => {
            const user = response.data || null
                commit('CHANGE_USER', user);
                renewUser(user);
            }
        );
    },
};

export const mutations = {
    LOGIN_REQUEST(state) {
        state.status = { loggingIn: true };
        state.user = null;
    },
    CHANGE_USER(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    CLEAN_USER(state) {
        state.status = {};
        state.user = null;
    }
};

