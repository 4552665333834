import store from '@/state/store'

export default [
    /*
     * ========================
     * Auth
     * ========================
     */
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/ms-login',
        name: 'ms-login',
        component: () => import('@/views/pages/account/ms-login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('@/views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logout')
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? {
                    name: 'home'
                } : {
                    path: (routeTo.query && routeTo.query.redirect) || routeFrom.path
                })
            },
        },
    },
    /*
     * ========================
     * Home / dashboard
     * ========================
     */
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/dashboard/index')
    },
    /*
     * ========================
     * Hub
     * ========================
     */
    {
        path: '/hub',
        name: 'hub',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/hub/index')
    },
        /*
     * ========================
     * Hub-notifications
     * ========================
     */
        {
            path: '/hub/notifications',
            name: 'hub',
            meta: {
                authRequired: true,
            },
            component: () => import('@/views/pages/hub/notifications')
        },
    /*
     * ========================
     * My Account
     * ========================
     */
    {
        path: '/profile',
        name: 'profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/account/profile')
    },
    {
        path: '/activity-logs',
        name: 'activity-logs',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/account/activity-log')
    },
    /*
     * ========================
     * Customers
     * ========================
     */
    {
        path: '/customers',
        name: 'customer',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/customers/list')
    },
    {
        path: '/customers/:id',
        name: 'edit-customer',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/customers/edit')
    },
    /*
     * ========================
     * Emails
     * ========================
     */
    {
        path: '/emails',
        name: 'email',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/emails/list')
    },
    {
        path: '/emails/:id',
        name: 'email-detail',
        meta: {
            authRequired: true,
        },
        query: {
            date:new Date().getTime()
        },
        component: () => import('@/views/pages/emails/detail')
    },
    {
        path: '/new-email/:customerId',
        name: 'new-email',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/emails/detail')
    },
    /*
     * ========================
     * Orders
     * ========================
     */
    {
        path: '/active-orders',
        name: 'active-order',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/orders/active-list')
    },
    {
        path: '/historical-orders',
        name: 'historical-order',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/orders/historical-list')
    },
    {
        path: '/cancelled-orders',
        name: 'cancelled-order',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/orders/cancelled-list')
    },
    {
        path: '/orders/:id',
        name: 'order-details',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/orders/detail')
    },
    /*
     * ========================
     * Payments
     * ========================
     */
    {
        path: '/payments',
        name: 'payments',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/payments/list')
    },
    /*
     * ========================
     * Invoices
     * ========================
     */
    {
        path: '/invoices',
        name: 'invoices',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/invoices/list')
    },
    {
        path: '/invoices/:id',
        name: 'invoice-details',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/invoices/detail')
    },
    /*
     * ========================
     * Delivery calendars
     * ========================
     */
    {
        path: '/delivery-calendars',
        name: 'delivery-calendars',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/delivery/calendar')
    },
    /*
     * ========================
     * Delivery price
     * ========================
     */
    {
        path: '/delivery-price',
        name: 'delivery-price',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/delivery/price-list')
    },
    /*
     * ========================
     * Example pages, will be removed
     * ========================
     */
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/apps/calendar',
        name: 'calendar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/calendar/index')
    },
    {
        path: '/apps/chat',
        name: 'chat',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/chat/index')
    },
    {
        path: '/auth/login-1',
        name: 'sample-login',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sample-auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sample-auth/register-1')
    },
    {
        path: '/auth/recoverpwd-1',
        name: 'recoverpwd-1',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sample-auth/recoverpwd-1')
    },
    {
        path: '/auth/lock-screen-1',
        name: 'lock-screen-1',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sample-auth/lock-screen-1')
    },
    {
        path: '/auth/two-step-verification',
        name: 'two-step-verification',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sample-auth/two-step-verification')
    },
    {
        path: '/auth/confirm-mail',
        name: 'confirm-mail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sample-auth/confirm-mail')
    },
    {
        path: '/auth/email-verification',
        name: 'email-verification',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/sample-auth/email-verification')
    },
    {
        path: '/pages/starter',
        name: 'starter',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/starter')
    },
    {
        path: '/pages/timeline',
        name: 'timeline',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/timeline')
    },
    {
        path: '/pages/invoice',
        name: 'invoice',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/invoice')
    },
    {
        path: '/pages/comingsoon',
        name: 'comingsoon',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/comingsoon')
    },
    {
        path: '/pages/profile',
        name: 'profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/profile')
    },
    {
        path: '/pages/pricing',
        name: 'pricing',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/pricing')
    },
    {
        path: '/pages/maintenance',
        name: 'maintenance',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/maintenance')
    },
    {
        path: '/email/inbox',
        name: 'Inbox',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/email/inbox')
    },
    {
        path: '/email/read/:id',
        name: 'Read Email',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/email/reademail')
    },
    {
        path: '/ui-components',
        name: 'ui-components',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/ui/index')
    },
    {
        path: '/advanced/sweet-alert',
        name: 'advanced-sweetalert',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/advanced/sweet-alert')
    },
    {
        path: '/advanced/rangeslider',
        name: 'rangeslider',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/advanced/rangeslider')
    },
    {
        path: '/advanced/notifications',
        name: 'notifications',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/advanced/notifications')
    },
    {
        path: '/advanced/carousel',
        name: 'carousel',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/advanced/carousel')
    },
    {
        path: '/typography',
        name: 'typography',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/typography')
    },
    {
        path: '/form/elements',
        name: 'elements',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/form/elements')
    },
    {
        path: '/form/advanced',
        name: 'advanced',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/form/advanced')
    },
    {
        path: '/tables/bootstrap',
        name: 'bootstrap-table',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/tables/bootstrap')
    },
    {
        path: '/tables/datatable',
        name: 'datatable',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/tables/datatable')
    },
    {
        path: '/charts',
        name: 'chart',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/charts')
    },
    {
        path: '/icons/boxicons',
        name: 'boxicons',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/boxicons')
    },
    {
        path: '/icons/fontawesome',
        name: 'fontawesome',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/fontawesome')
    },
    {
        path: '/icons/dripicons',
        name: 'dripicons',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/dripicons')
    },
    {
        path: '/icons/materialdesign',
        name: 'materialdesign',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/icons/materialdesign')
    },
    {
        path: '/maps',
        name: 'Google Maps',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/maps/index')
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/error/404')
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {
            authRequired: true,
        },
        component: () => import('@/views/pages/error/403')
    },
    { path: '*', redirect: '/404' }
]