import request from '@/helpers/request';

export function list(data) {
  return request.request({
    url: '/users',
    method: 'get',
    data: data,
  });
}

export function getInfo() {
  return request.request({
    url: '/users/info',
    method: 'get',
  });
}

export function update(data) {
  return request.request({
    url: '/users/update',
    method: 'put',
    data: data,
  });
}
