export const state = {
    order: {
        route: "",
        filter: "",
        filters: {},
        sortBy: "created_at",
        sortDesc: true,
    },
    customer: {}
};

export const mutations = {
    SET_ORDER_FILTER(state, filters) {
        state.order = filters;
    },
    SET_CUSTOMER_FILTER(state, filters) {
        state.customer = filters;
    }
};

export const actions = {
    changeOrderFilter({commit}, data) {
        commit('SET_ORDER_FILTER', data);
    },
    changeCustomerFilter({commit}, data) {
        commit('SET_CUSTOMER_FILTER', data);
    },
}