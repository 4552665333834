export function getToken() {
    return localStorage.getItem('token');
}

export function setToken(token) {
    return localStorage.setItem('token', token);
}

export function handleLogout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}

export function handleLogin(user, token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
}

export function renewUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
}